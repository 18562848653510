/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-else-return */
import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Trade, TradeType } from '@pancakeswap/sdk'
import { useToast } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import { ROUTER_ADDRESS, ROUTER_ADDRESS_PANCAKE } from 'config/constants/exchange'
import { useCallback, useMemo } from 'react'
import { logError } from 'utils/sentry'
import { Field } from '../state/swap/actions'
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import { computeSlippageAdjustedAmounts } from '../utils/exchange'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useTokenContract } from './useContract'
import useTokenAllowance from './useTokenAllowance'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// swap授权approve回调
// 返回一个指示审批状态的变量，以及一个在必要时进行审批或提前返回的函数
// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveOutCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string,
): [ApprovalState, () => Promise<void>] {
  const { account } = useWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined
  // swap查询是否授权 20230606
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  // console.log('currentAllowance====>', currentAllowance)
  const pendingApproval = useHasPendingApproval(token?.address, spender)
  // console.log('token===>', token)
  // console.log('spender===>', spender)
  
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) {
      // console.log('11111')
      return ApprovalState.UNKNOWN
    }
    // console.log('currency', amountToApprove)
    // console.log('spender', spender)
    if (amountToApprove.currency?.isNative) {
      // console.log('22222')
      return ApprovalState.APPROVED
    }
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) {
      // console.log('33333')
      return ApprovalState.UNKNOWN
    }
    
    // console.log('currentAllowance==>', currentAllowance.lessThan(amountToApprove))
    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])
  // console.log('444444====>', approvalState)
  // console.log('555555====>', ApprovalState.NOT_APPROVED)

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()
  
  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      toastError(t('Error'), t('Approve was called unnecessarily'))
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      toastError(t('Error'), t('No token'))
      console.error('no token')
      return
    }

    if (!tokenContract) {
      toastError(t('Error'), t('Cannot find contract of the token %tokenAddress%', { tokenAddress: token?.address }))
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      toastError(t('Error'), t('Missing amount to approve'))
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      toastError(t('Error'), t('No spender'))
      console.error('no spender')
      return
    }

    let useExact = false

    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString())
    })

    // eslint-disable-next-line consistent-return
    return callWithGasPrice(
      tokenContract,
      'approve',
      [spender, useExact ? amountToApprove.quotient.toString() : MaxUint256],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      },
    )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Approve ${amountToApprove.currency.symbol}`,
          translatableSummary: { text: 'Approve %symbol%', data: { symbol: amountToApprove.currency.symbol } },
          approval: { tokenAddress: token.address, spender },
          type: 'approve',
        })
      })
      .catch((error: any) => {
        logError(error)
        console.error('Failed to approve token', error)
        if (error?.code !== 4001) {
          toastError(t('Error'), error.message)
        }
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction, callWithGasPrice, t, toastError])
  // console.log('approvalState11====>', approvalState)

  return [approvalState, approve]
}

// wraps useApproveOutCallback in the context of a swap
export function useApproveOutCallbackFromTrade(
  trade?: Trade<Currency, Currency, TradeType>,
  allowedSlippage = 0,
  chainId?: number,
) {
  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.OUTPUT] : undefined),
    [trade, allowedSlippage],
  )
  // console.log('amountToApprove==>', amountToApprove)
  // console.log('chainId==>', ROUTER_ADDRESS[chainId])

  const swapTradeStatus = sessionStorage.getItem('swapTradeStatus')
  if(swapTradeStatus === "1"){
    // pancake
    return useApproveOutCallback(amountToApprove, ROUTER_ADDRESS_PANCAKE[chainId])
  }else{
    // bunnyswap
    return useApproveOutCallback(amountToApprove, ROUTER_ADDRESS[chainId])
  }
}

