import { Box, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 40px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 120px' : 'padding: 0 40px')};
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 348px;
`

export const TabletContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--colors-cardBorder);
  .tableComt {
    display: flex;
    justify-content: space-between;
    width: 160px;
    margin: auto;
  }
  a {
    display: inline-block;
    color: var(--colors-textSubtle);
    text-align: center;
    padding-bottom: 20px;
    :hover {
      opacity: .8;
    }
  }
  span {
    display: inline-block;
    color: var(--colors-textSubtle);
    text-align: center;
    padding-bottom: 20px;
    cursor: pointer;
    :hover {
      opacity: .8;
    }
  }
  .on {
    color: var(--colors-secondary);
    border-bottom: 2px solid var(--colors-secondary);
  }
`
